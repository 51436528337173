import { GlobalOutlined } from '@ant-design/icons';
import { currencyFormat } from '@mahawi/eshop-common/dist/src/currency-format';
import {
  getLowerCaseLanguageCode,
  getTranslation,
} from '@mahawi/eshop-common/dist/src/translation';
import {
  type IBasketProduct,
  type IOrderCurrencyType,
  type IOrderProduct,
} from '@mahawi/eshop-common/dist/src/types';
import { type RootState } from 'admin/react/reducers';
import { Skeleton, Space, Table, Typography } from 'antd';
import { type ColumnsType } from 'antd/es/table';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { type IConfigState } from '../../reducers/config/types';
import { type ILanguageState } from '../../reducers/language/types';
import CopyToClipboard from '../copy-to-clipboard';
import Link from '../link';

interface DataType {
  key: string;
  count: number;
  name: string;
  uuid: string;
  price: number;
  goodsWeight: number;
}

const { Text } = Typography;

function ProductTable({
  products,
  Language,
  Config,
  currencyType,
  withCopyLinks = false,
}: {
  products: IOrderProduct[] | IBasketProduct[] | undefined;
  Language: ILanguageState;
  Config: IConfigState;
  currencyType: IOrderCurrencyType;
  withCopyLinks?: boolean;
}): JSX.Element {
  const [dataSource, setDataSource] = useState<DataType[] | undefined>([]);
  const [columns, setColumns] = useState<ColumnsType<DataType>>([]);
  const [totalGoodsWeight, setTotalGoodsWeight] = useState<number>(0);

  useEffect((): void => {
    if (!products) {
      return;
    }

    const dataSourceUE = products.map((product) => {
      const name: string = getTranslation(product.names, Language.languageType);

      const price: number =
        product.price ||
        product.prices.find(
          ({ isoCode }): boolean => isoCode === currencyType.isoCode,
        ).price;

      return {
        key: product.uuid,
        count: product.count,
        name,
        uuid: product.uuid,
        price,
        goodsWeight: product.goodsWeight,
      };
    });

    const columnsUE: ColumnsType<DataType> = [
      {
        title: 'Count',
        dataIndex: 'count',
        key: 'count',
        render: (count: number): JSX.Element => (
          <Space size={16} align="start">
            <Text strong>{count}</Text>
            {withCopyLinks && (
              <CopyToClipboard text={String(count)} size="small" type="link" />
            )}
          </Space>
        ),
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (value: string, record: DataType): JSX.Element => (
          <Space size={16} align="start">
            <Link to={`/product/${record.uuid}`} label={value} openInNewTab />

            {withCopyLinks && (
              <CopyToClipboard text={value} size="small" type="link" />
            )}

            <a
              href={`${
                Config.url
              }/${getLowerCaseLanguageCode(Language.languageType)}/product/${
                record.uuid
              }`}
              target="_blank"
              rel="noreferrer noopener nofollow"
              aria-label="Open product in new tab"
            >
              <GlobalOutlined />
            </a>
          </Space>
        ),
      },
      {
        title: 'Price per unit with VAT',
        dataIndex: 'price',
        key: 'price',
        render: (price: number): JSX.Element => (
          <Space size={16} align="start">
            <Text strong>{currencyFormat(price, currencyType)}</Text>
            {withCopyLinks && (
              <CopyToClipboard text={String(price)} size="small" type="link" />
            )}
          </Space>
        ),
      },
      {
        title: 'Total goods weight [kg]',
        dataIndex: 'goodsWeight',
        key: 'goodsWeight',
      },
    ];

    setDataSource(dataSourceUE);
    setColumns(columnsUE);

    setTotalGoodsWeight(
      dataSourceUE.reduce(
        (acc: number, { goodsWeight, count }: DataType): number =>
          acc + goodsWeight * count,
        0,
      ),
    );
  }, [
    products,
    Language.languageType?.code,
    currencyType.isoCode,
    Config.url,
    withCopyLinks,
    currencyType,
  ]);

  if (!dataSource) {
    return <Skeleton active />;
  }

  if (dataSource.length === 0) {
    return <Typography.Paragraph>Empty</Typography.Paragraph>;
  }

  return (
    <>
      <Table dataSource={dataSource} columns={columns} />
      <Space size={16} align="start">
        <Typography.Text>
          Total goods weight: {totalGoodsWeight} kg
        </Typography.Text>
        <CopyToClipboard
          text={String(totalGoodsWeight)}
          size="small"
          type="link"
        />
      </Space>
    </>
  );
}

const mapStateToProps = ({ Language, Config }: RootState) => ({
  Language,
  Config,
});

export default connect(mapStateToProps)(ProductTable);
