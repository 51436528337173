import {
  DownloadOutlined,
  ReloadOutlined,
  SaveOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { notEmpty } from '@mahawi/eshop-common/dist/src/not-empty';
import { round } from '@mahawi/eshop-common/dist/src/round';
import { stringProbableMatch } from '@mahawi/eshop-common/dist/src/string-probable-match';
import { getTranslation } from '@mahawi/eshop-common/dist/src/translation';
import {
  EAdminWarehouseProductType,
  type IAdminWarehouseProductDetail,
  type IProduct,
  type IWarehouseCategory,
} from '@mahawi/eshop-common/dist/src/types';
import {
  Alert,
  Button,
  Col,
  Descriptions,
  Divider,
  Popconfirm,
  Row,
  Skeleton,
  Space,
  Tooltip,
  Typography,
} from 'antd';
import Markdown from 'markdown-to-jsx';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { type Dispatch } from 'redux';

import CopyToClipboard from '../components/copy-to-clipboard';
import WarehouseProductAdd from '../components/downloader/warehouse-product-add';
import Link from '../components/link';
import { type RootState } from '../reducers';
import {
  downloaderDownloadAllPhotosWarehouseProduct,
  downloaderLoadWarehouseProduct,
  downloaderSyncWarehouseProduct,
  downloaderWarehouseProductPairWithExistingProduct,
} from '../reducers/downloader/actions';
import { type IDownloaderState } from '../reducers/downloader/types';
import { type ILanguageState } from '../reducers/language/types';
import {
  productsGetByGTIN,
  productsGetByMPN,
} from '../reducers/products/actions';
import { type IProductsState } from '../reducers/products/types';

const categoriesHeaderEl: JSX.Element = (
  <Row gutter={16} key="header">
    <Col span={10}>
      <Typography.Paragraph strong>
        Warehosue category name
      </Typography.Paragraph>
    </Col>

    <Col span={2}>
      <Typography.Paragraph strong>Actions</Typography.Paragraph>
    </Col>

    <Col span={10}>
      <Typography.Paragraph strong>Category name</Typography.Paragraph>
    </Col>

    <Col span={2}>
      <Typography.Paragraph strong>Actions</Typography.Paragraph>
    </Col>
  </Row>
);

function DownloaderWarehouseProduct({
  dispatch,
  Downloader,
  Language,
  Products,
}: {
  dispatch: Dispatch;
  Downloader: IDownloaderState;
  Language: ILanguageState;
  Products: IProductsState;
}): JSX.Element {
  const { uuid } = useParams();
  const [product, setProduct] = useState<IAdminWarehouseProductDetail | null>(
    null,
  );
  const [categoriesEl, setCategoriesEl] = useState<JSX.Element[]>([]);
  const [nameNormalized, setNameNormalized] = useState<string>('');
  const [productsByMpn, setProductsByMpn] = useState<JSX.Element[]>([]);
  const [productsByGtin, setProductsByGtin] = useState<JSX.Element[]>([]);

  const reload = useCallback(
    (uuid: string | undefined): void => {
      if (uuid) {
        dispatch(downloaderLoadWarehouseProduct(uuid));
      }
    },
    [dispatch],
  );

  const sync = useCallback(
    (uuid: string | undefined): void => {
      if (uuid) {
        dispatch(downloaderSyncWarehouseProduct(undefined, uuid));
      }
    },
    [dispatch],
  );

  const downloadAllPhotos = useCallback(
    (uuid: string | undefined): void => {
      if (uuid) {
        dispatch(downloaderDownloadAllPhotosWarehouseProduct(uuid));
      }
    },
    [dispatch],
  );

  useEffect((): void => {
    reload(uuid);
  }, [uuid]);

  useEffect((): void => {
    if (!Downloader.warehouse.product) {
      return;
    }

    const nameNormalizedUE: string = Downloader.warehouse.product.name
      .replaceAll('/', ' ')
      .replaceAll(',', ' ')
      .replaceAll(/\s{2,}/g, ' ')
      .trim();

    setNameNormalized(nameNormalizedUE);
  }, [Downloader.warehouse.product]);

  useEffect((): void => {
    switch (Downloader.warehouse.product?.type) {
      case EAdminWarehouseProductType.DETAIL:
        setProduct(Downloader.warehouse.product);
        break;
      default:
        setProduct(null);
    }
  }, [Downloader.warehouse.product]);

  useEffect((): void => {
    const categoriesUE: JSX.Element[] =
      product?.categories
        .map((category: IWarehouseCategory): JSX.Element => {
          const categoryName: string = getTranslation(
            category.category?.names,
            Language.languageType,
          );

          return (
            <Row key={category.uuid} gutter={16}>
              <Col span={10}>
                <Typography.Paragraph>{category.name}</Typography.Paragraph>
              </Col>

              <Col span={2}>
                <Space size={16}>
                  <CopyToClipboard text={category.name} type="link" />

                  <Link
                    to={`/downloader/warehouse-category/${category.uuid}`}
                    openInNewTab
                  />
                </Space>
              </Col>

              <Col span={10}>
                {category.category && (
                  <Typography.Paragraph>{categoryName}</Typography.Paragraph>
                )}
              </Col>

              <Col span={2}>
                <Space size={16}>
                  {category.category?.uuid && (
                    <Link
                      to={`/category/${category.category.uuid}`}
                      openInNewTab
                    />
                  )}
                </Space>
              </Col>
            </Row>
          );
        })
        .filter(notEmpty) || [];

    setCategoriesEl([...[categoriesHeaderEl], ...categoriesUE]);
  }, [product, Language.languageType?.code]);

  useEffect((): void => {
    if (!Products.products) {
      setProductsByMpn([]);
      return;
    }

    const elements: JSX.Element[] = Products.products.map(
      ({ names, uuid: productUUID, gtin }: IProduct): JSX.Element => {
        const productName: string = getTranslation(
          names,
          Language.languageType,
        );

        return (
          <Row key={productUUID} gutter={16}>
            <Col span={10}>
              <Typography.Paragraph>{productName}</Typography.Paragraph>
            </Col>

            <Col span={2}>
              <Typography.Paragraph>{gtin || '---'}</Typography.Paragraph>
            </Col>

            <Col span={2}>
              <Space size={16}>
                <Link to={`/product/${productUUID}`} openInNewTab />
                <Popconfirm
                  title="Are you sure to pair with this product?"
                  description="Warehouse product will be paired with this product."
                  onConfirm={(): void => {
                    if (Downloader.warehouse.product?.uuid) {
                      dispatch(
                        downloaderWarehouseProductPairWithExistingProduct(
                          productUUID,
                          Downloader.warehouse.product?.uuid,
                        ),
                      );
                    }
                  }}
                >
                  <SaveOutlined />
                </Popconfirm>
              </Space>
            </Col>

            <Col span={2}>
              <Typography.Paragraph>
                {round(
                  stringProbableMatch({
                    first: productName,
                    second: nameNormalized,
                  }) * 100,
                  0,
                )}{' '}
                %
              </Typography.Paragraph>
            </Col>
          </Row>
        );
      },
    );

    setProductsByMpn(elements);
  }, [
    Products.products,
    Language.languageType?.code,
    Downloader.warehouse.product?.uuid,
    dispatch,
  ]);

  useEffect((): void => {
    if (!Products.products) {
      setProductsByGtin([]);
      return;
    }

    const elements: JSX.Element[] = Products.products.map(
      ({ names, uuid: productUUID, gtin }: IProduct): JSX.Element => {
        const productName: string = getTranslation(
          names,
          Language.languageType,
        );

        return (
          <Row key={productUUID} gutter={16}>
            <Col span={10}>
              <Typography.Paragraph>{productName}</Typography.Paragraph>
            </Col>

            <Col span={2}>
              <Typography.Paragraph>{gtin || '---'}</Typography.Paragraph>
            </Col>

            <Col span={2}>
              <Space size={16}>
                <Link to={`/product/${productUUID}`} openInNewTab />

                <Popconfirm
                  title="Are you sure to pair with this product?"
                  description="Warehouse product will be paired with this product."
                  onConfirm={() => {
                    if (Downloader.warehouse.product?.uuid) {
                      dispatch(
                        downloaderWarehouseProductPairWithExistingProduct(
                          productUUID,
                          Downloader.warehouse.product?.uuid,
                        ),
                      );
                    }
                  }}
                >
                  <SaveOutlined />
                </Popconfirm>
              </Space>
            </Col>

            <Col span={2}>
              <Typography.Paragraph>
                {round(
                  stringProbableMatch({
                    first: productName,
                    second: nameNormalized,
                  }) * 100,
                  0,
                )}{' '}
                %
              </Typography.Paragraph>
            </Col>
          </Row>
        );
      },
    );

    setProductsByGtin(elements);
  }, [
    Products.products,
    Language.languageType?.code,
    Downloader.warehouse.product?.uuid,
    dispatch,
  ]);

  if (Downloader.warehouse.product === undefined || !uuid) {
    return <Skeleton active />;
  }

  if (Downloader.warehouse.product === null) {
    return (
      <Alert
        message="Warehouse product not found"
        description={`Warehouse product with uuid ${uuid} not found.`}
        type="error"
        showIcon
      />
    );
  }

  return (
    <>
      <Typography.Title level={2}>Warehouse product</Typography.Title>

      <Divider />

      <Space size={16} align="start">
        <Tooltip title="Reload all information from database">
          <Button
            color="primary"
            onClick={(): void => {
              reload(uuid);
            }}
            disabled={Downloader.inProcess}
          >
            Reload <ReloadOutlined />
          </Button>
        </Tooltip>

        <Tooltip title="Sync product with warehouse">
          <Button
            color="primary"
            onClick={(): void => {
              sync(uuid);
            }}
            disabled={Downloader.inProcess}
          >
            Sync <ReloadOutlined />
          </Button>
        </Tooltip>

        <Tooltip title="Download all photos">
          <Button
            color="primary"
            onClick={(): void => {
              downloadAllPhotos(uuid);
            }}
            disabled={Downloader.inProcess}
          >
            Download all photos <DownloadOutlined />
          </Button>
        </Tooltip>
      </Space>

      <Divider />

      <Descriptions
        bordered
        column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
      >
        <Descriptions.Item label="UUID">
          <Space size={16}>
            {Downloader.warehouse.product.uuid}

            <CopyToClipboard
              text={Downloader.warehouse.product.uuid}
              type="link"
            />
          </Space>
        </Descriptions.Item>

        <Descriptions.Item label="Warehouse name">
          <Space size={16}>
            {Downloader.warehouse.product.warehouse.name}

            <CopyToClipboard
              text={Downloader.warehouse.product.warehouse.name}
              type="link"
            />
          </Space>
        </Descriptions.Item>

        <Descriptions.Item label="Warehouse UUID">
          <Space size={16}>
            {Downloader.warehouse.product.warehouse.uuid}

            <CopyToClipboard
              text={Downloader.warehouse.product.warehouse.uuid}
              type="link"
            />
          </Space>
        </Descriptions.Item>

        <Descriptions.Item label="Name">
          <Space size={16}>
            {Downloader.warehouse.product.name}

            <CopyToClipboard
              text={Downloader.warehouse.product.name}
              type="link"
            />
          </Space>
        </Descriptions.Item>

        <Descriptions.Item label="Name (normalized)">
          <Space size={16}>
            {nameNormalized}

            <CopyToClipboard text={nameNormalized} type="link" />
          </Space>
        </Descriptions.Item>

        <Descriptions.Item label="Brand">
          {Downloader.warehouse.product.brand}
        </Descriptions.Item>

        <Descriptions.Item label="MPN">
          <Space size={16}>
            {Downloader.warehouse.product.mpn}

            <CopyToClipboard
              text={Downloader.warehouse.product.mpn}
              type="link"
            />

            <SearchOutlined
              onClick={() => {
                if (Downloader.warehouse.product?.mpn) {
                  dispatch(productsGetByMPN(Downloader.warehouse.product.mpn));
                }
              }}
              disabled={!Downloader.warehouse.product?.mpn}
            />
          </Space>

          {productsByMpn}
        </Descriptions.Item>

        <Descriptions.Item label="GTIN">
          <Space size={16}>
            {Downloader.warehouse.product.gtin}

            <CopyToClipboard
              text={Downloader.warehouse.product.gtin}
              type="link"
            />

            <SearchOutlined
              onClick={(): void => {
                if (Downloader.warehouse.product?.gtin) {
                  dispatch(
                    productsGetByGTIN(Downloader.warehouse.product.gtin),
                  );
                }
              }}
              disabled={!Downloader.warehouse.product?.gtin}
            />
          </Space>

          {productsByGtin}
        </Descriptions.Item>

        <Descriptions.Item label="Weight">
          <Space size={16}>
            {Downloader.warehouse.product.dimensions.weight} Kg
          </Space>
        </Descriptions.Item>

        <Descriptions.Item label="Categories">
          {categoriesEl.map((el: JSX.Element): JSX.Element => el)}
        </Descriptions.Item>

        <Descriptions.Item label="Description">
          <Markdown>{product?.description || '---'}</Markdown>
        </Descriptions.Item>
      </Descriptions>

      <Divider />

      {!Downloader.newProduct && !Downloader.warehouse.product.product.uuid && (
        <WarehouseProductAdd />
      )}

      {Downloader.newProduct && !Downloader.warehouse.product.product.uuid && (
        <Row>
          <Col span={10} offset={8}>
            <Link
              to={`/product/${Downloader.newProduct.uuid}`}
              openInNewTab
              label={`Created new product with UUID ${Downloader.newProduct.uuid}`}
            />
          </Col>
        </Row>
      )}

      {!Downloader.newProduct && Downloader.warehouse.product.product.uuid && (
        <Row>
          <Col span={10} offset={8}>
            <Link
              to={`/product/${Downloader.warehouse.product.product.uuid}`}
              openInNewTab
              label={`Existing product with UUID ${Downloader.warehouse.product.product.uuid}`}
            />
          </Col>
        </Row>
      )}
    </>
  );
}

const mapStateToProps = ({ Downloader, Language, Products }: RootState) => ({
  Downloader,
  Language,
  Products,
});

export default connect(mapStateToProps)(DownloaderWarehouseProduct);
