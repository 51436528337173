import { green, red, yellow } from '@ant-design/colors';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { type IAdminCategory } from '@mahawi/eshop-common';
import { notEmpty } from '@mahawi/eshop-common/dist/src/not-empty';
import { getTranslation } from '@mahawi/eshop-common/dist/src/translation';
import { type RootState } from 'admin/react/reducers';
import { Button, Col, Collapse, Form, List, Row, Space } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { type Dispatch } from 'redux';

import { categoriesLoadAll } from '../../reducers/categories/actions';
import { type ICategoriesState } from '../../reducers/categories/types';
import { type ILanguageState } from '../../reducers/language/types';
import { saveCategories } from '../../reducers/product/actions';
import { type IProductState } from '../../reducers/product/types';
import SelectCategoryOptions from '../input/select-category-options';
import Link from '../link';

interface IForm {
  categoryUUIDs: string[];
}

const { Panel } = Collapse;

function CategoriesComponent({
  Categories,
  Product,
  Language,
  dispatch,
}: {
  Categories: ICategoriesState;
  Product: IProductState;
  Language: ILanguageState;
  dispatch: Dispatch;
}): JSX.Element {
  const [form] = Form.useForm<IForm>();

  const [statusIcon, setStatusIcon] = useState<JSX.Element>(
    <CloseCircleOutlined style={{ color: yellow.primary }} />,
  );
  const [categoriesElst, setCategoriesElst] = useState<JSX.Element[] | null>(
    null,
  );

  useEffect((): void => {
    form.setFieldsValue({
      categoryUUIDs: Product.categories.uuids,
    });

    const statusIconUE: JSX.Element = Product.categories.uuids.length ? (
      <CheckCircleOutlined style={{ color: green.primary }} />
    ) : (
      <CloseCircleOutlined style={{ color: red.primary }} />
    );

    setStatusIcon(statusIconUE);

    const categoriesElstUE: JSX.Element[] = Product.categories.uuids
      .map((categoryUUID: string): JSX.Element | undefined => {
        const category: IAdminCategory | undefined =
          Categories.categories?.find(
            (c: IAdminCategory): boolean => c.uuid === categoryUUID,
          );

        if (!category) {
          return;
        }

        const categoryNameLocalized: string = getTranslation(
          category?.names,
          Language.languageType,
        );

        return (
          <List.Item key={categoryUUID}>
            <Link
              to={`/category/${category.uuid}`}
              label={categoryNameLocalized}
              openInNewTab
            />
          </List.Item>
        );
      })
      .filter(notEmpty);

    setCategoriesElst(categoriesElstUE);
  }, [
    form,
    Product.categories.uuids,
    Categories.categories,
    Language.languageType,
    Product.updatedAt,
  ]);

  const onFinish = useCallback(
    ({ categoryUUIDs }: IForm, Product: IProductState): void => {
      dispatch(saveCategories(Product.uuid, categoryUUIDs));
    },
    [],
  );

  return (
    <Collapse
      onChange={(keys: string[]): void => {
        if (keys.includes('categories')) {
          dispatch(categoriesLoadAll());
        }
      }}
    >
      <Panel
        header={
          <Space size={8} align="start" direction="horizontal">
            <strong>Categories ({Product.categories.uuids.length})</strong>
            {statusIcon}
          </Space>
        }
        key="categories"
      >
        <Form
          form={form}
          onFinish={(values: IForm): void => onFinish(values, Product)}
          layout="vertical"
          initialValues={{
            categoryUUIDs: [],
          }}
        >
          <Row>
            <Col span={11}>
              <Form.Item
                name="categoryUUIDs"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <SelectCategoryOptions
                  form={form}
                  fieldName="categoryUUIDs"
                  isDisabled={Product.inProcess}
                  mode="multiple"
                />
              </Form.Item>
            </Col>

            <Col span={11} offset={2}>
              <List>{categoriesElst}</List>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Button
                type="primary"
                htmlType="submit"
                disabled={Product.inProcess}
                block
              >
                Save
              </Button>
            </Col>
          </Row>
        </Form>
      </Panel>
    </Collapse>
  );
}

const mapStateToProps = ({ Product, Categories, Language }: RootState) => ({
  Product,
  Categories,
  Language,
});

export default connect(mapStateToProps)(CategoriesComponent);
